<template>
  <v-dialog
    v-model="dialog"
    width="350"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        small
        v-bind="attrs"
        v-on="on"
        icon
        class="mt-n1"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <h2 class="body-1 font-weight-bold">
          Add product brand
        </h2>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          color="red"
          @click="dialog = false"
        >
          <v-icon small color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider class="mb-4"></v-divider>

      <v-card-text>
        <v-text-field
          dense
          outlined
          persistent-hint
          label="Name *"
          class="rounded-lg"
          v-model="brandObj.name"
          :hint="errors.get('name')"
          :error="errors.has('name')"
          @input="errors.clear('name')"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="px-6 pb-7">
        <v-btn
          large
          block
          type="submit"
          color="primary"
          :dark="!loading"
          :loading="loading"
          :disabled="loading"
          class="caption font-weight-bold rounded-lg"
          @click="submit()"
        >
          Save Product Brand Details
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Brand from '@/libs/retail/Brand'
import { mapActions } from 'vuex'

export default {
  props: {
    brand: {
      type: Object
    }
  },

  data () {
    return {
      dialog: false,
      loading: false,
      brandObj: new Brand(),
    }
  },

  computed: {
    errors () {
      return this.brandObj.form.errors
    },
  },

  methods: {
    ...mapActions([
      'setBrands',
    ]),

    store () {
      this.brandObj.store().then(() => {
        this.setBrands().then(() => {
          this.dialog = false
        })
      }).finally(() => {
        this.loading = false
      })
    },

    update () {
      this.brandObj.update(this.brand.brand_uid)
        .finally(() => {
          this.loading = false
        })
    },

    submit () {
      if (!this.loading) {
        this.loading = true
        this.brand ? this.update() : this.store()
      }
    },
  },
}
</script>