import Base from '@/libs/core/Base'

const fields = {
  name: null,
}

export default class Brand extends Base {
  constructor () {
    super(fields);
  }

  store () {
    return new Promise(async (resolve, reject) => {
      try {
        const data = this.getFields()
        const response = await this.form.submit('post', 'retail/brands', data)
        this.setFields(fields)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }

  update (brandUid) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = this.getFields()
        const response = await this.form.submit('patch', `retail/brands/${brandUid}`, data)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}
